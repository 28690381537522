import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Navbar.css"

const Navbar = () => {
    return (
        <>
        
            <nav id="navbar_top" className="navbar  navbar-expand-lg m-0 p-2" style={{ backgroundColor: "#030404", borderBottom: "1px solid white" }} >
                <div className="container p-2 ">
                    <div className='ms-lg-4 ps-lg-5'>
                        <NavLink to="/">
                            <img className='w-100' style={{ height: "3.2rem" }} src={require("../img/LOGONEW.png")} alt="icloudsoft_logo" />
                        </NavLink>
                    </div>
                    <button className="navbar-toggler me-3 p-2" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse   " id="navbarNavDropdown">
                        <div className='d-flex justify-content-lg-end me-lg-5 w-100  '>
                            <ul className="navbar-nav  d-flex justify-content-lg-end  w-100  " >

                               
                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className="text-decoration-none" to='tel:9527468898' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER YESEVA' style={{ fontSize: "16px" }} > <i className="fas fa-phone"></i> +91 9527468898   </h6> </NavLink>
                                </li>
                                <li className='nav-item ps-lg-1 pe-lg-4'>
                                    <NavLink className="text-decoration-none" to='mailto:info@cloudsoft.co.in' >  <h6 className='ms-lg-2 FontMontSerret BOOKINGHEADER YESEVA' style={{ fontSize: "16px" }} > Email :  info@icloudsoft.com  </h6> </NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </nav>

        </>
    )
}

export default Navbar
import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Comman.css"

const Footer = () => {
    return (
        <>
            <section>
                <div className="container-fluid mt-5" style={{ backgroundImage: `url(${require("../img/footer.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', }}>
                    <div className="container text-white text-center  p-lg-5 pt-3">
                        <div className="row pt-4">
                            <h2 className='pb-3 UbuntuFONTFAMILY' style={{ fontSize: "35px", fontWeight: "400" }} >Get In Touch With Us</h2>
                            <p className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: "17px" }} > <NavLink className='FooterWebsiteLinkHOVER ' to='https://icloudsoft.in/'><span>Cloud Solutions Provider Company</span></NavLink> | <NavLink className='FooterWebsiteLinkHOVER' to='https://icloudsoftware.co.in'><span>Software Deveolpment Company</span></NavLink> | <NavLink className='FooterWebsiteLinkHOVER' to='https://icloudsoftware.co.in'><span>Web Design & Development Company</span></NavLink> | <NavLink className='FooterWebsiteLinkHOVER' to='https://icloudsoft.co.in/'><span>Digital Marketing Company</span></NavLink> </p>
                            <p className='pb-2 UbuntuFONTFAMILY' style={{ fontSize: "17px" }} >  Kothrud | Pune | India </p>
                            <div className='UbuntuFONTFAMILY' style={{ fontSize: "18px", fontWeight: "300" }}>
                                <i class="fa-solid fa-phone p-1"  ></i><NavLink className="text-decoration-none text-white p-1" exact to="tel:9527468898" ><b>+91 9527468898</b></NavLink>
                            </div>
                            <div className='pt-3 fs-5 UbuntuFONTFAMILY'>
                                Email:<NavLink className='emailHover' to="mailto:info@soft.co.in"> <span className='text-center fs-5 '>info@cloudsoft.co.in</span></NavLink>
                            </div>

                            <div className=''>
                                <div className='row d-flex justify-content-lg-center  justify-content-center align-items-center  pt-4' style={{ cursor: "pointer" }}>
                                    <div className='col-lg-12 '>
                                        <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram fs-4  SocialIcon " ></i></NavLink>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f fs-4 ms-4 SocialIcon"></i></NavLink>
                                        <NavLink to="https://wa.me/9527468898" target="_blank" title="Chat on WhatsApp"><i class="fa-brands fa-whatsapp fs-4 ms-4 SocialIcon"></i></NavLink>
                                        <NavLink to='https://twitter.com/icloudsoftTech' target="_blank"><i className="fa-brands fa-twitter fs-4 ms-4 SocialIcon"></i> </NavLink>
                                        <NavLink to='https://in.linkedin.com/in/anil-kadam-18b113147' target="_blank"><i className="fa-brands fa-linkedin fs-4 ms-4 SocialIcon"></i> </NavLink>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer
import React from 'react'
import { NavLink } from 'react-router-dom'

const Home = () => {
    return (
        <>

            <section>
                <div className="container-fluid  p-0" style={{ backgroundImage: `url(${require("../img/homeBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: ' center,center ', height: "75vh" }}>
                    {/* <div className='pt-5  position-absolute  translate-middle' style={{ top: "45%", left: "20%" }} >
                        <h2 className='text-white ' style={{ fontSize: "50px",fontWeight:"700" }} >ICLOUDSOFT </h2>
                        <h2 className='text-white ms-5' style={{ fontSize: "50px" }} >TECHNOLOGY </h2>
                    </div> */}
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }}>
                    <div className="container  pt-5 p-lg-2 FontMontSerret"  >
                        <div className="pt-lg-5 pb-lg-5">
                            <h2 className='mt-4 mb-4 pt-2 pb-2 text-white HomeFontRALEWAYLIGHT' style={{ fontSize: "36px", fontWeight: "400" }} > OUR LATESH WORKS</h2>

                            <div className="row g-5 pt-5 ">
                                <div className="col-lg-4 ">
                                    <div className='imgHover '>
                                        <NavLink to='https://icloudsoft.in/'><img loading='lazy' className='w-100 ' style={{ height: "17rem" }} src={require("../img/1.png")} title='iCloudsoft' alt='iCloudsoft' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://icloudsoft.in/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Cloud Solution</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Future plan your business IT setup using the modern technologies to enhance protection and security, today, tomorrow and throughout the growing years. </p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://www.icloudsoftware.co.in/'><img loading='lazy' className='w-100 ' style={{ height: "17rem" }} src={require("../img/2.png")} title='iCloudsoftWeb' alt='iCloudsoftWeb' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://www.icloudsoftware.co.in/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Software Development</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > iCloudsoft Technology provides high-quality software and web development services to clients. For over 10 years, we have perfected the art of software testing, as well as quality assurance services. </p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://icloudsoft.co.in/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/8.png")} title='digitalmarketing' alt='digitalmarketing' /></NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://icloudsoft.co.in/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Digital Marketing</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > iCloudsoft specialize in client growth through budget-friendly solutions. Our comprehensive services include digital marketing, web development, eCommerce, Android app development, SEO, and branding</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 pt-5 ">
                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://www.pashumitra.in/'> <img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/4.png")} title='pashumitra' alt='pashumitra' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://www.pashumitra.in/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Pashumitra</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Pashumitra is one of the largest providers of cattle feed and agricultural products in Maharashtra. We provide products that are essential for cattle farming, poultry farming, and small-scale agriculture </p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://icloudsoftech.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/5.png")} title='drone' alt='drone' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://icloudsoftech.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >DRONE & CCTV Rental</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} >  iCloud Tech, the trusted provider of professional Drone and CCTV services. Our commitment to safety, professionalism, and cutting-edge technology sets us apart. We are licensed, insured, and strictly adhere to regulations to ensure a secure and compliant operation.</p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://nikitamanpowerservices.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/6.png")} title='nikitamanpower' alt='nikitamanpower' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://nikitamanpowerservices.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Nikita Man Power Services</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Nikita Man Power Services  provides all types of manpower like skilled, semi skill & unskilled manpower, requirements in line with their customer’s needs, utilizing appropriate screening and matching methods to ensure that only suitably qualified persons are recommended, in a timely to provide a qualified staff.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 pt-5 ">
                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://ecloudweb.icloudsoftdigital.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/9.png")} title='shreesamarth' alt='shreesamarth' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://ecloudweb.icloudsoftdigital.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Samarth Group</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, totam quod officiis consequatur deleniti alias, quaerat libero labore eius autem, voluptatem dolor reprehenderit explicabo nobis quae ipsam ex nemo laudantium?</p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://skywardtelecom.co.in/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/3.png")} title='skyword' alt='skyword' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://skywardtelecom.co.in/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Skyward Telecom</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Skyward, a well-known name in the field of telecom solutions, has definitely made a niche for itself in the field. The firm offers multi-vendor solutions for Real Estate, Security and Surveillance, Video conferencing solutions</p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://critindia.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/7.png")} title='cirtindia' alt='cirtindia' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://critindia.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Consulting & Services</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Connecting Roots IT delivers end-to-end value through innovation process and SAP technology consulting plus a range of other services. We provide feasible solutions to your problems by providing you SAP solutions at a reasonable cost. We are true pioneers as we make the best of experts in the industry, work for you  </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 pt-5 ">
                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://cab.icloudsoftdigital.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/cab_rental.png")} title='cab_rental' alt='cab_rental' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://cab.icloudsoftdigital.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Cab Rental</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Experience the freedom of exploring your city at your own pace. With our premium taxi rental service, your journey becomes more than just a ride – it becomes an adventure. Book with us today and enjoy the luxury of convenience, comfort, and reliability. Your destination awaits! </p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://industrial.icloudsoftdigital.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/industrial.png")} title='industrial' alt='industrial' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://industrial.icloudsoftdigital.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Industrial</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} >  iCloudsoft understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of customers.</p>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://grocerry.icloudsoftdigital.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/grocerry.png")} title='grocerry' alt='grocerry' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://grocerry.icloudsoftdigital.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Supar Market </h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > At our Super Market, we are committed to bringing you the freshest and finest products, carefully selected to meet the highest standards. From farm-fresh produce to premium meats, our aisles are stocked with a diverse range of quality goods to satisfy all your culinary needs. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 pt-5 ">
                                <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://icloudsoftdigital.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/hotel_car_rental.png")} title='hotel_car_rental' alt='hotel_car_rental' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <NavLink className='text-decoration-none' to='https://icloudsoftdigital.com/'>
                                            <h2 className='pt-2 pb-2 HoVeREffect' style={{ fontSize: "28px", cursor: "pointer" }} >Hotel or Car Rental</h2>
                                        </NavLink>
                                        <p className='' style={{ fontSize: "14px" }} > Whether you're traveling for business or leisure, Green Paradise is your trusted partner in creating memorable and hassle-free experiences. Book with us and embark on a journey where comfort, luxury, and convenience converge. </p>
                                    </div>
                                </div>

                                {/* <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://skywardtelecom.co.in/ '><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/3.png")} title='skyword' alt='skyword' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <h2 className='' style={{ fontSize: "28px" }} >Industrial</h2>
                                        <p className='' style={{ fontSize: "14px" }} >Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, totam quod officiis consequatur deleniti alias, quaerat libero labore eius autem, voluptatem dolor reprehenderit explicabo nobis quae ipsam ex nemo laudantium?</p>
                                    </div>
                                </div> */}

                                {/* <div className="col-lg-4">
                                    <div className='imgHover'>
                                        <NavLink to='https://critindia.com/'><img loading='lazy' className='w-100' style={{ height: "17rem" }} src={require("../img/7.png")} title='cirtindia' alt='cirtindia' /> </NavLink>
                                    </div>
                                    <div className='pt-4'>
                                        <h2 className='' style={{ fontSize: "28px" }} >Supar Market </h2>
                                        <p className='' style={{ fontSize: "14px" }} >Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, totam quod officiis consequatur deleniti alias, quaerat libero labore eius autem, voluptatem dolor reprehenderit explicabo nobis quae ipsam ex nemo laudantium?</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Home
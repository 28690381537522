import React from 'react'
import { Routes, Route } from "react-router-dom"
import Home from './Home'
import Navbar from './Navbar'
import Footer from './Footer'
import Error from './Error'

const Routing = () => {
    return (
        <>
            <React.Fragment>
                <header className='sticky-top'>
                    <Navbar />
                </header>
                <main>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="*" element={<Error />} />
                    </Routes>
                </main>
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>
        </>
    )
}

export default Routing